<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          icon="mdi-clipboard"
          title="Últimos Equipamentos Autorizados"
          :footer-props="{
            itemsPerPageText: 'Itens por Página'
          }"
        >
          <v-row>
            <v-col
              cols="12"
              md="12"
              class="mt-10"
            >
              <v-simple-table
                class="ml-2"
              >
                <thead>
                  <tr>
                    <th class="text-left">
                      O.S.
                    </th>
                    <th class="text-left">
                      Nome
                    </th>
                    <th class="text-left">
                      Equipamento
                    </th>
                    <th class="text-left">
                      Data de Autorização
                    </th>
                    <th class="text-left">
                      Data de Previsão
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(order, i) in orders"
                    :key="i"
                  >
                    <td v-text="order.id" />
                    <td v-text="order.client" />
                    <td v-text="order.productmodel" />
                    <td v-text="order.authorization_datetime" />
                    <td v-text="order.prevision_datetime" />
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <base-material-card
          icon="mdi-email-newsletter"
          title="Novas Requisições"
          :footer-props="{
            itemsPerPageText: 'Itens por Página'
          }"
        >
          <v-row>
            <v-col
              cols="12"
              md="12"
              class="mt-10"
            >
              <v-simple-table
                class="ml-2"
              >
                <thead>
                  <tr>
                    <th class="text-left">
                      Número
                    </th>
                    <th class="text-left">
                      Nome
                    </th>
                    <th class="text-left">
                      Modelo do Produto
                    </th>
                    <th class="text-left">
                      Whatsapp
                    </th>
                    <th class="text-left">
                      Email
                    </th>
                    <th class="text-left">
                      Solicitado
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(request, i) in requests"
                    :key="i"
                  >
                    <td v-text="request.id" />
                    <td v-text="request.name" />
                    <td v-text="request.productmodel">
                    <td v-text="request.whatsapp">
                    <td v-text="request.email">
                    <td v-text="request.created" />
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import ServiceRequestsService from '@/services/ServiceRequestsService'
  import OrdersService from '@/services/OrdersService'
  import dayjs from 'dayjs'

  export default {
    name: 'Dashboard',

    data () {
      return {
        requests: [],
        orders: [],
      }
    },

    computed: {
      
    },

    mounted () {
      this.getRecentRequests(),
      this.getRecentAuthorizedOrders()
    },

    methods: {
      getRecentRequests() {
        ServiceRequestsService.getRecentServicesRequests()
        .then(response => {
          let obj = {}
          response.data.service_requests.forEach(element => {

            obj = {
              id: element.id,
              name: element.name,
              productmodel: element.productmodel,
              whatsapp: element.whatsapp,
              email: element.email,
              created: dayjs(element.created).format('DD/MM/YY'),
            }
            this.requests.push(obj);
          });
        })
        .catch(error => console.log(error))
      },
      getRecentAuthorizedOrders () {
        OrdersService.getRecentAuthorizedOrders()
        .then(response => {
          this.items = []
          let obj = {}
          response.data.orders.forEach(element => {
            obj = {
              id: element.id,
              title: element.title,
              cpf: element.client.cpf,
              productmodel: element.productmodel,
              serial_number: element.serial_number,
              status: element.status,
              code: element.code,
              approval: element.approval,
              client: element.client.name,
              modified: dayjs(element.modified).format('DD/MM/YY HH:mm:ss'),
              arrival: dayjs(element.arrival_date).format('DD/MM/YY')+' '+element.arrival_time,
              modified_by: element.user.name,
              modified_by_in: element.user.name+' '+dayjs(element.modified).format('DD/MM/YY HH:mm:ss'),
              authorization_datetime: dayjs(element.authorization_date).format('DD/MM/YY')+' '+element.authorization_time,
              prevision_datetime: !element.prevision_date || element.prevision_date == '' ?  '-' : dayjs(element.prevision_date).format('DD/MM/YY')+' '+element.authorization_time,
            }
            this.orders.push(obj);
          });
        })
        .catch(error => console.log(error))
      },
    },
  }
</script>
